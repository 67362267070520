import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import HomepageTemplate from "gatsby-theme-carbon/src/templates/Homepage";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const SquareCard = makeShortcode("SquareCard");
const Carousel = makeShortcode("Carousel");
const MediumPosts = makeShortcode("MediumPosts");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Бренды`}</h2>
    <p>{`Мировые бренды и российские производители - выбирайте решение, которое
оптимально подходит для вашего производства. Квалифицированная техническая
поддержка позволит быстро найти правильное решение`}</p>
    <Row className="image-card-group homepage-cards" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="" href="/" actionIcon="arrowRight" mdxType="ImageCard">
          <img {...{
            "src": "/images/brands/01.png",
            "alt": "Square"
          }}></img>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
  <ImageCard title="" aspectRatio="1:1" href="/" actionIcon="arrowRight" mdxType="ImageCard">
          <img {...{
            "src": "/images/brands/02.png",
            "alt": "Light dark"
          }}></img>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
  <ImageCard title="" aspectRatio="1:1" href="/" actionIcon="arrowRight" mdxType="ImageCard">
          <img {...{
            "src": "/images/brands/03.png",
            "alt": "Light dark"
          }}></img>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
  <ImageCard title="" aspectRatio="1:1" href="/" actionIcon="arrowRight" mdxType="ImageCard">
          <img {...{
            "src": "/images/brands/04.png",
            "alt": "Light dark"
          }}></img>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
  <ImageCard title="" aspectRatio="1:1" href="/" actionIcon="arrowRight" mdxType="ImageCard">
          <img {...{
            "src": "/images/brands/05.png",
            "alt": "Light dark"
          }}></img>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
  <ImageCard title="" aspectRatio="1:1" href="/" actionIcon="arrowRight" mdxType="ImageCard">
          <img {...{
            "src": "/images/brands/06.png",
            "alt": "Light dark"
          }}></img>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
  <ImageCard title="" aspectRatio="1:1" href="/" actionIcon="arrowRight" mdxType="ImageCard">
          <img {...{
            "src": "/images/brands/07.png",
            "alt": "Light dark"
          }}></img>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
  <ImageCard title="" aspectRatio="1:1" href="/" actionIcon="arrowRight" mdxType="ImageCard">
          <img {...{
            "src": "/images/brands/08.png",
            "alt": "Light dark"
          }}></img>
  </ImageCard>
      </Column>
      <SquareCard title="" color="dark" href="/brands/list" helperText="Все бренды" mdxType="SquareCard" />
    </Row>
    <h2>{`Избранные категории`}</h2>
    <p>{`Индустриальные решения отсортированные нашими экспертами и партнёрами`}</p>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
  <ImageCard title="" aspectRatio="1:1" href="/" actionIcon="arrowRight" mdxType="ImageCard">
          <img {...{
            "src": "/images/categories/01.png",
            "alt": "Light dark"
          }}></img>
  </ImageCard>
  <ImageCard title="" aspectRatio="2:1" href="/" actionIcon="arrowRight" mdxType="ImageCard">
          <img {...{
            "src": "/images/categories/03.png",
            "alt": "Light dark"
          }}></img>
  </ImageCard>
      </Column>
      <Column colMd={8} colLg={8} noGutterSm mdxType="Column">
  <ImageCard title="" aspectRatio="1:1" href="/" actionIcon="arrowRight" mdxType="ImageCard">
          <img {...{
            "src": "/images/categories/02.png",
            "alt": "Light dark"
          }}></img>
  </ImageCard>
      </Column>
      <Column colMd={8} colLg={8} noGutterSm mdxType="Column">
  <ImageCard title="" aspectRatio="2:1" href="/" actionIcon="arrowRight" mdxType="ImageCard">
          <img {...{
            "src": "/images/categories/04.png",
            "alt": "Light dark"
          }}></img>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
  <ImageCard title="" aspectRatio="1:1" href="/" actionIcon="arrowRight" mdxType="ImageCard">
          <img {...{
            "src": "/images/categories/05.png",
            "alt": "Light dark"
          }}></img>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
  <ImageCard title="" aspectRatio="1:1" href="/" actionIcon="arrowRight" mdxType="ImageCard">
          <img {...{
            "src": "/images/categories/06.png",
            "alt": "Light dark"
          }}></img>
  </ImageCard>
  <ImageCard title="" aspectRatio="2:1" href="/" actionIcon="arrowRight" mdxType="ImageCard">
          <img {...{
            "src": "/images/categories/09.png",
            "alt": "Light dark"
          }}></img>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
  <ImageCard title="" aspectRatio="1:1" href="/" actionIcon="arrowRight" mdxType="ImageCard">
          <img {...{
            "src": "/images/categories/07.png",
            "alt": "Light dark"
          }}></img>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
  <ImageCard title="" aspectRatio="1:2" href="/" actionIcon="arrowRight" mdxType="ImageCard">
          <img {...{
            "src": "/images/categories/08.png",
            "alt": "Light dark"
          }}></img>
  </ImageCard>
      </Column>
    </Row>
    <h2>{`Новинки`}</h2>
    <Carousel items={[{
      href: "/",
      image: "/images/new/01.png",
      heading: "GROB",
      copy: "Новый высокопроизводительный фрезерный обрабатывающий центр G700",
      color: "inverse"
    }, {
      href: "/",
      image: "/images/new/01.png",
      heading: "KINTEK",
      copy: "Новая высокоэффективная зажимная система для станков серии GENIUS"
    }, {
      href: "/",
      image: "/images/new/01.png",
      heading: "Indersoll Rand",
      copy: "Насосы нового поколения серии TREK UP"
    }, {
      href: "/",
      image: "/images/new/01.png",
      heading: "SANDVIK",
      copy: "Приницпиально новый стружколом WIPER для высокоропроизводительной обработки заколанных сталей"
    }, {
      href: "/",
      image: "/images/new/01.png",
      heading: "SANDVIK",
      copy: "Приницпиально новый стружколом WIPER для высокоропроизводительной обработки заколанных сталей"
    }, {
      href: "/",
      image: "/images/new/01.png",
      heading: "SANDVIK",
      copy: "Приницпиально новый стружколом WIPER для высокоропроизводительной обработки заколанных сталей"
    }]} mdxType="Carousel" />
    <h2>{`Статьи и обзоры`}</h2>
    <MediumPosts color="dark" postLimit={4} mdxType="MediumPosts" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      